import React, { useState, useEffect } from 'react'
import './Home.css'
import Hero from '../Hero/Hero'
import Offers from '../Offers/Offers'
import NewestLaptops from '../NewestLaptops/NewestLaptops'
import NewestMobile from '../NewestMobile/NewestMobile'


const Home = (props) => {

  const { offerItems, laptopItems, mobileItems, addProduct, getItemid } = props;


  return (
    <div>

      <Hero />

      <Offers
        items={offerItems}
        addProduct={addProduct}
        getItemid={getItemid}
      />

      <NewestLaptops
        laptopItems={laptopItems}
        addProduct={addProduct}
        getItemid={getItemid}
      />

      <NewestMobile
        mobileItems={mobileItems}
        addProduct={addProduct}
        getItemid={getItemid}
      />

      {/* <Shop items={items}/> */}
    </div>
  )
}

export default Home