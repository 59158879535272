import React from 'react'
import { useState, useEffect } from 'react'
import './Hero.css'
import img1 from '../../assets/1.png'
import img2 from '../../assets/2.png'
import img3 from '../../assets/3.png'
import img4 from '../../assets/4.png'
import img5 from '../../assets/5.png'
import img6 from '../../assets/6.png'
import img7 from '../../assets/7.png'
import img8 from '../../assets/8.png'
import img9 from '../../assets/9.png'
import img10 from '../../assets/10.png'

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, EffectFade, Autoplay } from "swiper";

const Hero = () => {

  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  // const [slidesPerVs, setSlidesPerVs] = useState()


  useEffect(() => {

    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener('resize', changeWidth)

    return () => {
      window.removeEventListener('resize', changeWidth)
    }

  }, [])


  return (
    <section className="hero container mt-5">

      <div className="row top-hero  ">

        <div className="col-12 col-md-7 my-2 ">

          <Swiper
            modules={[Pagination, Navigation, EffectFade, Autoplay]}
            loop={true}
            // pagination={true}
            pagination={{
              clickable: true,
            }}
            navigation={false}
            autoplay={{ delay: 5000, disableOnInteraction: true }}
            className="swiper mx-4">

            <SwiperSlide>
              <a href="#"> <img src={img10} alt="" className="w-100" /> </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="#"> <img src={img8} alt="" className="w-100" /> </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="#"> <img src={img9} alt="" className="w-100" /> </a>
            </SwiperSlide>

          </Swiper>

        </div>

        <div className="col-12 col-md-5 my-2 ">

          <div className="row d-flex flex-column">

            <div className="col-12">

              <div className="img-container3 d-flex align-items-center justify-content-around  " >
                <a href="#"> <img src={img5} alt="" className="d-block" /> </a>
                <a href="#"> <img src={img6} alt="" className="d-block" /> </a>
              </div>

            </div>

            <div className="col-12 text-center my-4">
              <a href="#"> <img src={img7} alt="" className="w-100" /> </a>


            </div>

          </div> {/* end of child row */}

        </div>

      </div> {/* end of father row */}

      <div className="row my-5 bottom-swiper">



        <Swiper
          autoplay={{ delay: 5000, disableOnInteraction: true }}
          slidesPerView={screenWidth >= 1400 ? 4 : screenWidth >= 1070 ? 3 : screenWidth >= 800 ? 2 : 1}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Autoplay]}
          className="mySwiper d-block table-responsive text-center" id='bottom-swiper'>

          <SwiperSlide> <a href="#"> <img src={img1} alt="" /> </a> </SwiperSlide>
          <SwiperSlide> <a href="#"> <img src={img2} alt="" /> </a> </SwiperSlide>
          <SwiperSlide> <a href="#"> <img src={img3} alt="" /> </a> </SwiperSlide>
          <SwiperSlide> <a href="#"> <img src={img4} alt="" /> </a> </SwiperSlide>

        </Swiper>



      </div> {/* end of last row */}

    </section>
  )
}

export default Hero