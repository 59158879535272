import React, { useState } from 'react'
import './AboutProduct.css'
//import functions
import formatCurrency from '../../function/formatCurrency'
import discount from '../../function/discount'

import wall from '../../assets/wall.webp'

import { FiCreditCard, FiWatch, FiArchive, FiGift, FiCheck } from "react-icons/fi";

//for magnifyer
import ReactImageMagnify from 'react-image-magnify';

///swiper 
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper";

// improt magnifier
import {
    Magnifier,
    GlassMagnifier,
    SideBySideMagnifier,
    PictureInPictureMagnifier,
    MOUSE_ACTIVATION,
    TOUCH_ACTIVATION,
    MagnifierContainer,
    MagnifierPreview,
    MagnifierZoom
} from "react-image-magnifiers";



const AboutProduct = (props) => {

    const { selectedItem, addProduct } = props

    ///swiper
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    const [selectedColer, setSelectedColer] = useState();

    return (
        <section className="about-product container ">
            <div className="row d-flex align-items-start justify-content-between ">

                <div className="col-12 col-lg-5 img mt-5 ">
                    {/******* for swiper ******/}

                    <Swiper
                        spaceBetween={10}
                        navigation={true}
                        thumbs={{ swiper: thumbsSwiper }}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className="mySwiper2"
                    >

                        {
                            selectedItem.photos.map((photo, index) => (
                                <SwiperSlide key={index}>
                                    {/* <img src={photo} className="w-100" key={index} /> */}

                                    <GlassMagnifier
                                        magnifierSize="50%"
                                        imageSrc={photo}
                                        imageAlt="Example"
                                    />

                                </SwiperSlide>
                            ))
                        }

                    </Swiper>

                    

                </div> { /* end of img container */}

                <div className=" col-12 col-lg-7 d-flex flex-column mt-5">
                    <div className="row d-flex align-items-center justify-content-between mb-5" >
                        <div className="col-12 col-md-6 " >
                            <h2> {selectedItem.type} {selectedItem.company} {selectedItem.name} </h2>

                            {/* <p>
                        {selectedItem.type == " لپ تاپ "  ? 
                        <p> yes </p> :
                        <p> no </p> 
                         }
                            
                    </p> */}

                            <ul className="ditail-container my-5 ">
                                <li> <span className="ditail-title"> » ابعاد :</span>
                                    <span className="ditail"> ۸.۹×۱۶۳.۳×۷۷.۹ <p></p> </span>
                                </li>
                                <li> <span className="ditail-title"> » وزن :</span>
                                    <span className="ditail"> 230 گرم <p></p> </span>
                                </li>
                                <li> <span className="ditail-title"> » رم :</span>
                                    <span className="ditail"> {selectedItem.ram} گیگابایت<p></p> </span>
                                </li>
                                <li> <span className="ditail-title"> » حافظه :</span>
                                    <span className="ditail"> {selectedItem.memory} گیگابایت<p></p> </span>
                                </li>
                                <li> <span className="ditail-title"> » دوربین :</span>
                                    <span className="ditail">  {selectedItem.camera} گیگابایت<p></p>  </span>
                                </li>
                                <li> <span className="ditail-title"> » شبکه های ارتباطی :</span>
                                    <span className="ditail">  2G, 3G, 4G, 5G <p></p>  </span>
                                </li>
                            </ul>

                        </div>

                        <div className="col-12 col-md-6 ditails-price-container ">
                            <div className="d-flex align-items-center justify-content-between">
                                <h4 className="mb-5" > رنگ: <span className="d-none" > سفید </span> </h4>
                                <div className="row colors mb-5 ps-0 ">

                                    {
                                        selectedItem.colorsCode.map((color, index) => (
                                            <div
                                                key={index}
                                                onClick={() => setSelectedColer(index)}
                                                className={selectedColer === index ? "color col-6 col-md-3  active-color" : "color  col-6 col-md-3"}
                                                style={{ backgroundColor: `#${color}` }}
                                            >
                                                {
                                                    selectedColer === index ?
                                                        <FiCheck className={color === "ffffff" || color === "f4dcd8" ? "text-dark" : "text-light"} /> :
                                                        <FiCheck className="d-none" />
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>

                            {
                                selectedItem.discount > 0 &&
                                <h5 className="discount d-flex align-items-center justify-content-end">
                                    <span className="discount-price ms-3" > {formatCurrency(selectedItem.price)} </span>
                                    <span className="percent" > {selectedItem.discount} % </span>
                                </h5>
                            }

                            <h4 className="mb-5 d-flex align-items-center justify-content-between" >
                                <span>
                                    قیمت :
                                </span>
                                <span>
                                    {formatCurrency(discount(selectedItem.price, selectedItem.discount))} تومان
                                </span>
                            </h4>
                            <button className="button btn1 w-100" onClick={() => addProduct(selectedItem)} data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" > افزودن به سبد خرید </button>
                        </div>
                    </div>

                    <div className="row mt-5 ">
                        <div className="row icons d-flex align-items-center justify-content-between">
                            <div className="text-center col-6 col-md-3 " >
                                <FiCreditCard className="icon text-center even" />
                                <p className="mt-4  text-center" > پرداخت با تمامی کارت ها </p>
                            </div>
                            <div className="text-center col-6 col-md-3 " >
                                <FiWatch className="icon text-center" />
                                <p className="mt-4 text-center " > تحویل در سریع ترین زمان </p>
                            </div>
                            <div className="text-center col-6 col-md-3 " >
                                <FiArchive className="icon text-center even" />
                                <p className="mt-4 text-center " > بسته بندی مطمئن </p>
                            </div>
                            <div className="text-center col-6 col-md-3 " >
                                <FiGift className="icon text-center" />
                                <p className="mt-4 text-center " > همراه با هدیه ای ناقابل </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div> {/* end of row */}

            <div className="about-product-text-container">
                <p className="about-product-text">
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه
                    و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز،
                    و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد،
                    تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد،
                    در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها،
                    و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.
                </p>
                {/* <img src={wall2} className="w-50 text-center" alt="" /> */}
                <p className="about-product-text">
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه
                    و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز،
                    و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد،
                    تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد،
                    در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها،
                    و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.
                </p>
                <img src={wall} alt="" className="img-fluid" />
                <p className="about-product-text">
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه
                    و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز،
                    و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد،
                    تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد،
                    در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها،
                    و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.
                </p>
            </div>

        </section >
    )
}

export default AboutProduct

