import React from 'react'
import './ShopCart.css'
import { FiX, FiPlus, FiMinus, FiTrash2 } from "react-icons/fi";
import { Link } from 'react-router-dom'

import formatCurrency from '../../function/formatCurrency'
import discount from '../../function/discount'


const ShopCart = (props) => {

    const { shopItems, totalLenght, totalPrice, totalPriceWithoutDiscount, addProduct, removeProduct, removeProductAll } = props;

    return (
        <section className='shop-cart container'>

            <div className="row ">
                <div className="col-12 col-lg-9 mt-4 table-responsive-md">

                    {
                        totalLenght === 0 ?
                            <h1 className="text-center" > سبد خرید شما خالی است </h1> :
                            <table className="table text-center">
                                <thead>
                                    <tr>
                                        <th scope="col"> </th>
                                        <th scope="col"> </th>
                                        <th scope="col"> محصول </th>
                                        <th scope="col"> قیمت واحد </th>
                                        <th scope="col"> تعداد </th>
                                        <th scope="col"> قیمت مجموع </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* نمایش محصولات خریداری شده */}
                                    {
                                        shopItems.map((shopItem) => (
                                            <tr key={shopItem.id} className="" >
                                                <td>  <button type="button" className="close-btn p-2" onClick={() => removeProductAll(shopItem)} > <FiX /> </button> </td>

                                                <td >
                                                    <div className="image-container">
                                                        <img src={shopItem.photo} />
                                                    </div>
                                                </td>

                                                <td> {shopItem.name} </td>
                                                <td>
                                                    <div className="d-flex flex-column">
                                                        <span>  {formatCurrency(discount(shopItem.price, shopItem.discount))}  تومان </span>
                                                        {
                                                            shopItem.discount > 0 ?
                                                                <span className="discount-price" >  {formatCurrency(shopItem.price)} تومان </span> :
                                                                <span className="d-none" ></span>
                                                        }
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className="input-group num-btn">
                                                        <button
                                                            className="button btn1 inc-dic-btn"
                                                            type="button"
                                                            onClick={() => removeProduct(shopItem)}
                                                        >
                                                            {shopItem.qty === 1 ? <FiTrash2 /> : <FiMinus />}
                                                        </button>

                                                        <input type="text" value={shopItem.qty} className="form-control text-center fs-4" readOnly />
                                                        <button
                                                            className="button btn1 inc-dic-btn"
                                                            type="button"
                                                            onClick={() => addProduct(shopItem)}
                                                        >
                                                            <FiPlus />
                                                        </button>
                                                    </div>
                                                </td>
                                                <td >
                                                    <div className="d-flex flex-column">
                                                        <span>
                                                            {formatCurrency(discount(shopItem.price, shopItem.discount) * shopItem.qty)} تومان
                                                        </span>
                                                        {
                                                            shopItem.discount > 0 ?
                                                                <span className="discount-price" >  {formatCurrency(shopItem.price * shopItem.qty)} تومان </span> :
                                                                <span className="d-none" ></span>
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }

                                </tbody>
                            </table>
                    }

                </div> {/* end of col 7 */}

                {/* نمایش قیمت محصولات */}

                <div className="col-12 col-lg-3 mt-4 price-section ">

                    <h2 className="mb-5 text-center" > جمع کل سبد خرید </h2>

                    <h5 className="d-flex align-items-center justify-content-between mb-4 " >
                        <span> قیمت کالا ها ( {totalLenght} ) : </span>
                        <span> {formatCurrency(totalPriceWithoutDiscount)} تومان </span>
                    </h5>

                    <hr />

                    <h5 className="d-flex align-items-center justify-content-between mb-4 " >
                        <span> تخفیف : </span>
                        <span> {formatCurrency(totalPriceWithoutDiscount - totalPrice)} تومان  </span>
                    </h5>

                    <hr />

                    <h4 className="d-flex align-items-center justify-content-between mb-5" >
                        <span>مبلغ قابل پرداخت : </span>
                        <span> {formatCurrency(totalPrice)} تومان </span>
                    </h4>

                    <Link to="/check-out" >
                        <button className="button btn1 w-100"> ادامه جهت تسویه حساب </button>
                    </Link>

                </div> {/* end of col 5 */}

            </div>  {/* end of row */}

        </section>
    )
}

export default ShopCart