import React, { useState, useEffect } from 'react'
import './Offers.css'
import Timer from '../Timer/Timer'
import { Link } from 'react-router-dom'

// Swiper imports
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, EffectFade, Autoplay } from "swiper";

// icon imports
import { FiShoppingCart } from "react-icons/fi";

//function imports
import discount from '../../function/discount'
import formatCurrency from '../../function/formatCurrency'




const Offers = (props) => {

  const { items, addProduct, getItemid } = props


  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  useEffect(() => {

    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener('resize', changeWidth)

    return () => {
      window.removeEventListener('resize', changeWidth)
    }

  }, [])

  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#ff906e" fillOpacity="1" d="M0,224L80,197.3C160,171,320,117,480,128C640,139,800,213,960,224C1120,235,1280,181,1360,154.7L1440,128L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path></svg>



      <section className="offres ">


        <div className="container">

          <div className="title d-flex align-items-start justify-content-between " >
            <h1 className='text-white mb-5' > پر تخفیف ترین محصولات امروز </h1>
            <Timer duration={24 * 60 * 60 * 1000} />
          </div>

          <Swiper
            autoplay={{ delay: 5000, disableOnInteraction: true }}
            slidesPerView={screenWidth >= 1400 ? 4 : screenWidth >= 1070 ? 3 : screenWidth >= 800 ? 2 : 1}
            spaceBetween={30}
            navigation={true}
            modules={[Pagination, Navigation, Autoplay]}
            className="mySwiper d-block table-responsive text-center" id='bottom-swiper'>

            {
              items.map((item) => (
                <SwiperSlide key={item.id}>
                   <Link to={`/shop/${item.id}`} onClick={() => { getItemid(item); window.scroll({ top: 0 }) }} key={item.id}>
                    <div className="col">
                      <div className="card"  >
                        <img src={item.photo} className="card-img-top" alt="" />
                        {
                          item.discount > 0 ?
                            <span> %{item.discount} </span> :
                            <span className="d-none" ></span>

                        }
                        <div className="card-body m-3">
                          <h5 className="card-title"> گوشی {item.company} {item.name} با حافظه {item.memory} Gb با رم {item.ram} Gb </h5>
                          <div className="price-and-btn-container">
                            
                            <a
                            
                              href="#offcanvasExample"
                              data-bs-toggle="offcanvas"
                              onClick={() => addProduct(item)}
                            >
                              <button className="button btn1 card-text" title=" افزودن به سبد خرید " >
                                <FiShoppingCart />
                              </button>
                              
                            </a>

                            <div className="d-flex align-items-center" >
                              {
                                item.discount > 0 ?
                                  <p className="card-text befor-price fs-5 mt-3 mx-4">

                                    {`${formatCurrency(item.price)} تومان `}
                                  </p> :
                                  <p className="d-none"></p>
                              }
                              <p className="card-text final-price fs-4 ">
                                {
                                  formatCurrency(discount(item.price, item.discount))
                                }
                              </p>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
              ))
            }

          </Swiper>
        </div>

      </section>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#ff906e" fillOpacity="1" d="M0,192L48,208C96,224,192,256,288,240C384,224,480,160,576,149.3C672,139,768,181,864,181.3C960,181,1056,139,1152,117.3C1248,96,1344,96,1392,96L1440,96L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path></svg>


    </>
  )
}

export default Offers