import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import { FiChevronUp } from "react-icons/fi";

// import components
import Header from '../src/components/Header/Header'
import Home from '../src/components/Home/Home'
import Footer from '../src/components/Footer/Footer'
import ShopList from '../src/components/ShopList/ShopList'
import Shop from '../src/components/Shop/Shop'
import ShopCart from '../src/components/ShopCart/ShopCart'
import Checkout from '../src/components/Checkout/Checkout'
import AboutProduct from '../src/components/AboutProduct/AboutProduct'
import Login from '../src/components/Login/Login'
import Fortest from '../src/components/Fortest/Fortest'

// import data
import details from '../src/data/details.json'

//import styles
import './app.css';



function App() {

  const [offerItems, setOfferItems] = useState(details.offers)
  const [laptopItems, setLaptopItems] = useState(details.laptopData)
  const [mobileItems, setMobileItems] = useState(details.mobileData)

  const [shopData, setShopData] = useState(details)

  const [showToTopBtn, setShowToTopBtn] = useState(false)


  // send data to local storage start
  const getDataFromLs = () => {
    const data = localStorage.getItem('shopItems');
    if (data) {
      return JSON.parse(data)
    } else {
      return [];
    }
  }

  /// insert shop to shop list start 

  const [shopItems, setShopItems] = useState(getDataFromLs())

  const addProduct = (product) => {

    const exist = shopItems.find((element) => element.id === product.id)
    if (exist) {
      setShopItems(
        shopItems.map((element) => element.id === product.id ? { ...exist, qty: exist.qty + 1 } : element)
      )
    } else {
      setShopItems([...shopItems, { ...product }])
    }
  }
  /// insert shop to shop list end

  useEffect(() => {
    localStorage.setItem('shopItems', JSON.stringify(shopItems));
    //for to top btn
    window.addEventListener('scroll', () =>{
      if(window.pageYOffset > 400){
        setShowToTopBtn(true);
      } else{
        setShowToTopBtn(false);
      }
    })
  }, [shopItems])

  // send data to local storage end

  // delet shop to shop list end

  const removeProduct = (product) => {

    const exist = shopItems.find((element) => element.id === product.id)

    if (exist.qty === 1) {
      setShopItems(
        shopItems.filter((element) => element.id !== product.id)
      )
    } else {
      setShopItems(
        shopItems.map((element) => element.id === product.id ? { ...exist, qty: exist.qty - 1 } : element)
      )
    }

  }
  // delet shop to shop list end

  // delet all of one product from shop list start

  const removeProductAll = (product) => {

    const exist = shopItems.find((element) => element.id === product.id)

    setShopItems(
      shopItems.filter((element) => element.id !== product.id)
    )
  }
  //  delet all of one product from shop list end

  //calculate total price
  const totalPrice = shopItems.reduce((a, c) => a + c.price * ((100 - c.discount) / 100) * c.qty, 0);
  const totalPriceWithoutDiscount = shopItems.reduce((a, c) => a + c.price * c.qty, 0);

  //calculate total Lenght
  const totalLenght = shopItems.reduce((a, c) => a + c.qty, 0);

  // get item id
  const [itemId, setItemId] = useState()
  const [selectedItem, setSelectedItem] = useState([])


  // send data to AboutProduct to show a product details
  const getItemid = (item) => {
    setItemId(item.id);
    setSelectedItem(item)
  }

  return (
    <BrowserRouter >

      <a href="#" className={showToTopBtn ? "to-top active" : "to-top"} >
        <FiChevronUp />
      </a>

      <Header
        totalLenght={totalLenght}
      />

      <ShopList
        shopItems={shopItems}
        removeProduct={removeProduct}
        totalPrice={totalPrice}
        totalLenght={totalLenght}
      />

      <Routes >

        <Route path="/" exact element={
          <Home
            offerItems={offerItems}
            laptopItems={laptopItems}
            mobileItems={mobileItems}
            addProduct={addProduct}
            getItemid={getItemid}
          />
        } />

        <Route path="/shop-cart" element={
          <ShopCart
            shopItems={shopItems}
            totalPrice={totalPrice}
            totalLenght={totalLenght}
            totalPriceWithoutDiscount={totalPriceWithoutDiscount}
            addProduct={addProduct}
            removeProduct={removeProduct}
            removeProductAll={removeProductAll}
          />
        } />

        <Route path="/check-out" element={
          <Checkout
            shopItems={shopItems}
            totalPrice={totalPrice}
          />
        } />

        <Route path="/shop" exact element={
          <Shop
            mobileItems={mobileItems}
            shopData={shopData}
            addProduct={addProduct}
            getItemid={getItemid}

          />
        } />

        <Route path="/shop/:id" element={
          <AboutProduct
            shopItem={"id"}
            mobileItems={mobileItems}
            selectedItem={selectedItem}
            addProduct={addProduct}
          />
        } />

        <Route path="/login" element={
          <Login />

        } />


      </Routes>

      <Footer />

    </BrowserRouter>
  )
}

export default App;

