import React from 'react'
import './ShopList.css'
import { Link } from 'react-router-dom'
import { FiTrash2, FiTrash } from "react-icons/fi";

import formatCurrency from '../../function/formatCurrency'
import discount from '../../function/discount'

const ShopList = (props) => {

    const { shopItems, removeProduct, totalLenght, totalPrice } = props;

    return (
        <section>

            <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" >
                <div className="offcanvas-header ">
                    {
                        shopItems.length == 0 ?
                            <h4 className="offcanvas-title" id="offcanvasExampleLabel" > سبد خرید شما خالی است </h4> :
                            <h4 className="offcanvas-title" id="offcanvasExampleLabel" > {totalLenght} محصول در سبد خرید است </h4>
                    }
                    <button type="button" className="btn-close text-reset fs-4" data-bs-dismiss="offcanvas" ></button>
                    <button type="button" className="btn-close text-reset fs-4" id="close" data-bs-dismiss="offcanvas"></button>
                </div>

                <div className="offcanvas-body">

                    {
                        shopItems.map((item) => (

                            <div key={item.id} className="shop-container row mt-4 d-flex align-items-center justify-content-between">
                                <div className="img-container col-5">
                                    <img src={item.photo} alt="" className="w-100" />
                                </div>

                                <div className="details-container col-7">

                                    {
                                        item.id >= 1 && item.id <= 16 ?
                                            <p className="detail">
                                                {item.type} {item.company} مدل {item.name} با حافظه {item.memory} Gb و رم {item.ram} Gb دوربین {item.camera} Mp
                                            </p> : item.id >= 35 && item.id <= 46 ?
                                                <p className="detail">
                                                    {item.type} {item.company} مدل {item.name} CPU {item.cpu} RAM {item.ram} Gb کارت گرافیک {item.gpu} با حافظه {item.memory} Gb
                                                </p> :
                                                <p className="detail">
                                                     {item.type} {item.company} مدل {item.name} 
                                                </p>
                                    }

                                    <p className="discount-price"> {formatCurrency(item.price)} تومان </p>

                                    {
                                        item.discount > 0 ?
                                            <p className="price"> {formatCurrency(discount(item.price, item.discount))} تومان  </p> :
                                            <p className="d-none">  </p>
                                    }
                                    <p className="qty"> تعداد: {item.qty} عدد </p>
                                    <button className="button btn2 fs-5" onClick={() => removeProduct(item)} > <FiTrash2 />  حذف از سبد خرید </button>

                                </div>

                            </div>

                        ))
                    }

                </div>
                <div className="total-price px-3 ">
                    <div className="d-flex align-items-center justify-content-between mb-2" >
                        {
                            totalPrice > 0 ?
                                <h2> مجموع قیمت :</h2> :
                                <p className="d-none"></p>
                        }
                        {
                            totalPrice > 0 ?
                                <h2 className="mt-2" > {formatCurrency(totalPrice)} تومان </h2> :
                                <p className="d-none"></p>
                        }
                    </div>
                    <Link to="/check-out" onClick={() => window.scroll({ top: 0 }) } >
                        <button className="button btn2 w-100 my-2" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" > تسویه حساب </button>
                    </Link>
                    <Link to="/shop-cart" onClick={() => window.scroll({ top: 0 }) } >
                        <button className="button btn1 w-100 my-2 mb-3" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" > مشاهده سبد خرید </button>
                    </Link>

                </div>
            </div>

        </section>
    )
}

export default ShopList

//<Link to={`/shop/${item.id}`} onClick={() => { getItemid(item); window.scroll({ top: 0 }) }} key={item.id}>