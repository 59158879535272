import React from 'react'
import './Shop.css'
import { Link } from 'react-router-dom'
import { FiShoppingCart } from "react-icons/fi";

import discount from '../../function/discount'
import formatCurrency from '../../function/formatCurrency'

const Shop = (props) => {

  const { getItemid, addProduct, shopData } = props

  //OOP

  return (
    <section>
      <div className="container ">
        <div className='row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-4' >

          {
            shopData.mobileData.map((item) => (
              <Link to={`/shop/${item.id}`} onClick={() => { getItemid(item); window.scroll({ top: 0 }) }} key={item.id}>
                <div className="col" >
                  <div className="card">
                    <img src={item.photo} className="card-img-top" alt="" />
                    {
                      item.discount > 0 ?
                        <span> %{item.discount} </span> :
                        <span className="d-none" ></span>

                    }
                    <div className="card-body m-3">
                      <h5 className="card-title"> {item.type} {item.company} {item.name} با حافظه {item.memory} Gb با رم {item.ram} Gb </h5>
                      <div className="price-and-btn-container">
                        <button
                          className="button btn1 card-text"
                          title=" افزودن به سبد خرید "
                          onClick={() => addProduct(item)}
                          data-bs-target="#offcanvasExample"
                          data-bs-toggle="offcanvas"
                        >
                          <FiShoppingCart />
                        </button>
                        <div className="d-flex align-items-center" >
                          {
                            item.discount > 0 ?
                              <p className="card-text befor-price fs-5 mt-3 mx-4">
                                {
                                  formatCurrency(item.price)
                                }
                              </p> :
                              <p className="d-none"></p>
                          }
                          <p className="card-text final-price fs-4 "> {
                            `${formatCurrency(discount(item.price, item.discount))} تومان `
                          } </p>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

              </Link>
            ))
          }

          {
            shopData.laptopData.map((item) => (
              <Link to={`/shop/${item.id}`} onClick={() => { getItemid(item); window.scroll({ top: 0 }) }} key={item.id}>
                <div className="col">
                  <div className="card">
                    <img src={item.photo} className="card-img-top" alt="" />
                    {
                      item.discount > 0 ?
                        <span> %{item.discount} </span> :
                        <span className="d-none" ></span>

                    }
                    <div className="card-body m-3">
                      <h5 className="card-title"> {item.type} {item.company} {item.name} با حافظه {item.memory} Gb با رم {item.ram} Gb </h5>
                      <div className="price-and-btn-container">
                        <Link to={"/shop"} >
                          <button
                            className="button btn1 card-text"
                            title=" افزودن به سبد خرید "
                            onClick={(e) => { e.preventdefault(); addProduct(item) }}
                            data-bs-target="#offcanvasExample"
                            data-bs-toggle="offcanvas"
                          >
                            <FiShoppingCart />
                          </button>
                        </Link>
                        <div className="d-flex align-items-center" >
                          {
                            item.discount > 0 ?
                              <p className="card-text befor-price fs-5 mt-3 mx-4">
                                {
                                  formatCurrency(item.price)
                                }
                              </p> :
                              <p className="d-none"></p>
                          }
                          <p className="card-text final-price fs-4 "> {
                            `${formatCurrency(discount(item.price, item.discount))} تومان `
                          } </p>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </Link>
            ))
          }

          {
            shopData.powerBankData.map((item) => (
              <Link to={`/shop/${item.id}`} onClick={() => { getItemid(item); window.scroll({ top: 0 }) }} key={item.id}>                <div className="col" >
                <div className="card">
                  <img src={item.photo} className="card-img-top" alt="" />
                  {
                    item.discount > 0 ?
                      <span> %{item.discount} </span> :
                      <span className="d-none" ></span>

                  }
                  <div className="card-body m-3">
                    <h5 className="card-title"> {item.type} {item.company} {item.name} با حافظه {item.memory} Gb با رم {item.ram} Gb </h5>
                    <div className="price-and-btn-container">
                      <button
                        className="button btn1 card-text"
                        title=" افزودن به سبد خرید "
                        onClick={() => addProduct(item)}
                        data-bs-target="#offcanvasExample"
                        data-bs-toggle="offcanvas"
                      >
                        <FiShoppingCart />
                      </button>
                      <div className="d-flex align-items-center" >
                        {
                          item.discount > 0 ?
                            <p className="card-text befor-price fs-5 mt-3 mx-4">
                              {
                                formatCurrency(discount(item.price, item.discount))
                              }
                            </p> :
                            <p className="d-none"></p>
                        }
                        <p className="card-text final-price fs-4 "> {
                          `${formatCurrency(item.price)} تومان `
                        } </p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              </Link>
            ))
          }

          {
            shopData.coverData.map((item) => (
              <Link to={`/shop/${item.id}`} onClick={() => { getItemid(item); window.scroll({ top: 0 }) }} key={item.id}>
                <div className="col">
                  <div className="card">
                    <img src={item.photo} className="card-img-top" alt="" />
                    {
                      item.discount > 0 ?
                        <span> %{item.discount} </span> :
                        <span className="d-none" ></span>

                    }
                    <div className="card-body m-3">
                      <h5 className="card-title"> {item.type} {item.company} {item.name} با حافظه {item.memory} Gb با رم {item.ram} Gb </h5>
                      <div className="price-and-btn-container">
                        <button
                          className="button btn1 card-text"
                          title=" افزودن به سبد خرید "
                          onClick={() => addProduct(item)}
                          data-bs-target="#offcanvasExample"
                          data-bs-toggle="offcanvas"
                        >
                          <FiShoppingCart />
                        </button>
                        <div className="d-flex align-items-center" >
                          {
                            item.discount > 0 ?
                              <p className="card-text befor-price fs-5 mt-3 mx-4">
                                {
                                  formatCurrency(discount(item.price, item.discount))
                                }
                              </p> :
                              <p className="d-none"></p>
                          }
                          <p className="card-text final-price fs-4 "> {
                            `${formatCurrency(item.price)} تومان `
                          } </p>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </Link>
            ))
          }

          {
            shopData.handsfree.map((item) => (
              <Link to={`/shop/${item.id}`} onClick={() => { getItemid(item); window.scroll({ top: 0 }) }} key={item.id}>
                <div className="col" >
                  <div className="card">
                    <img src={item.photo} className="card-img-top" alt="" />
                    {
                      item.discount > 0 ?
                        <span> %{item.discount} </span> :
                        <span className="d-none" ></span>

                    }
                    <div className="card-body m-3">
                      <h5 className="card-title"> {item.type} {item.company} {item.name} با حافظه {item.memory} Gb با رم {item.ram} Gb </h5>
                      <div className="price-and-btn-container">
                        <button
                          className="button btn1 card-text"
                          title=" افزودن به سبد خرید "
                          onClick={() => addProduct(item)}
                          data-bs-target="#offcanvasExample"
                          data-bs-toggle="offcanvas"
                        >
                          <FiShoppingCart />
                        </button>
                        <div className="d-flex align-items-center" >
                          {
                            item.discount > 0 ?
                              <p className="card-text befor-price fs-5 mt-3 mx-4">
                                {
                                  formatCurrency(discount(item.price, item.discount))
                                }
                              </p> :
                              <p className="d-none"></p>
                          }
                          <p className="card-text final-price fs-4 "> {
                            `${formatCurrency(item.price)} تومان `
                          } </p>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </Link>
            ))
          }

        </div>
      </div>
    </section>
  )
}

export default Shop