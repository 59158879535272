import { React, useState } from 'react'
import './Login.css'

// for hide and show password
import { FiEyeOff, FiEye } from "react-icons/fi";


const Login = () => {

  // for hide and show password
  const [showPass, setShowPass] = useState(false);

  //for check that user had acount or not
  const [hasAccount, setHasAccount] = useState(false);

  return (
    <div className="login container">

      <form className={
        hasAccount ?
        "log-in-container":
        "d-none "
      } >
        <h2 className="text-center"> ورود به سایت </h2>

        <lable className="form-lable" htmlFor="user-name" > نام کاربری </lable>
        <input className="form-control mb-4 mt-3 form-control-lg " type="text" id="user-name" />

        <lable className="form-lable" htmlFor="password" > رمز عبور </lable>
        <div className="pass d-flex align-items-center justify-content-between">
          {
            showPass ?
              <FiEyeOff className="pass-icon" onClick={() => setShowPass(!showPass)} /> :
              <FiEye className="pass-icon" onClick={() => setShowPass(!showPass)} />
          }
          <input className="form-control mb-2 mt-3 form-control-lg" id="password"
            type={
              showPass ?
                "text" :
                "password"
            }
          />
        </div>
        <a href="#" className="link-text"> رمز عبور خود را فراموش کرده ام </a> <br />

        <button type="submit" className="button btn1 w-100 mt-5 pb-3"> ورود </button>
        <a href="#"
          className="link-text my-3"
          onClick={() =>setHasAccount(!hasAccount)}
        >
          ثبت نام نکرده اید؟ ایجاد حساب کاربری
        </a>

      </form>

      <form className={
        hasAccount=== false ?
        "register-container ":
        "d-none "
      }>
        <h2 className="text-center mb-4"> ثبت نام در سایت </h2>

        <lable className="form-lable" htmlFor="phone-number" > شماره تلفن همراه </lable>
        <input className="form-control mb-4 mt-3 form-control-lg " type="phone" id="phone-number" />

        <lable className="form-lable" htmlFor="user-name" > نام کاربری </lable>
        <input className="form-control mb-4 mt-3 form-control-lg " type="text" id="user-name" />

        <lable className="form-lable " htmlFor="password" > رمز عبور </lable>
        <div className="pass d-flex align-items-center justify-content-between">

          <input className="form-control mb-4 mt-3 form-control-lg" id="password"
            type="password"
          />
        </div>

        <lable className="form-lable" htmlFor="password" > تکرار رمز عبور </lable>
        <div className="pass d-flex align-items-center justify-content-between">
          <input className="form-control mb-4 mt-3 form-control-lg" id="password"
            type="password"
          />
        </div>

        <button type="submit" className="button btn1 w-100 mt-5 pb-3"> ایجاد حساب کاربری </button>
        <a href="#"
          className="link-text my-3"
          onClick={() =>setHasAccount(!hasAccount)}
        >
          حساب کاربری دارید؟ ورود به حساب کاربری
        </a>

      </form>

    </div>
  )
}

export default Login