import React, { useState, useEffect } from 'react'
import './NewestLaptops.css'
import { Link } from 'react-router-dom'

// Swiper imports
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, EffectFade, Autoplay } from "swiper";

// icon imports
import { FiShoppingCart } from "react-icons/fi";

//function imports
import discount from '../../function/discount'
import formatCurrency from '../../function/formatCurrency'

const NewestLaptops = (props) => {
    const { laptopItems, addProduct, getItemid } = props

    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    useEffect(() => {

        const changeWidth = () => {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('resize', changeWidth)

        return () => {
            window.removeEventListener('resize', changeWidth)
        }

    }, [])

    return (
        <section className='NewestLaptops' >
            <div className="container">

                <div className=" d-flex align-items-start justify-content-between " >
                    <h1 className='mb-5' > جدید ترین لپ تاپ ها </h1>
                    <Link to="/shop" >
                        <button className="button btn2"> مشاهده فروشگاه </button>
                    </Link>
                </div>

                <Swiper
                    autoplay={{ delay: 5000, disableOnInteraction: true }}
                    slidesPerView={screenWidth >= 1400 ? 4 : screenWidth >= 1070 ? 3 : screenWidth >= 800 ? 2 : 1}
                    spaceBetween={30}
                    navigation={true}
                    modules={[Pagination, Navigation, Autoplay]}
                    className="mySwiper d-block table-responsive " id='bottom-swiper'>

                    {
                        laptopItems.map((laptopItem) => (
                            <SwiperSlide key={laptopItem.id}>
                                 <Link to={`/shop/${laptopItem.id}`} onClick={() => { getItemid(laptopItem); window.scroll({ top: 0 }) }} key={laptopItem.id}>
                                    <div className="col" >
                                        <div className="card">
                                            <img src={laptopItem.photo} className="card-img-top" alt="" />
                                            {
                                                laptopItem.discount > 0 ?
                                                    <span> %{laptopItem.discount} </span> :
                                                    <span className="d-none" ></span>
                                            }
                                            <div className="card-body m-3">
                                                <h5 className="card-title"> لپ تاپ {laptopItem.company} مدل {laptopItem.name} CPU {laptopItem.cpu} RAM {laptopItem.ram} Gb کارت گرافیک {laptopItem.gpu} با حافظه {laptopItem.memory} Gb </h5>
                                                <div className="price-and-btn-container">
                                                    <a
                                                        href="#offcanvasExample"
                                                        data-bs-toggle="offcanvas"
                                                        onClick={() => addProduct(laptopItem)} >

                                                        <button
                                                            className="button btn1 card-text"
                                                            title=" افزودن به سبد خرید " >
                                                            <FiShoppingCart />
                                                        </button>
                                                    </a>
                                                    <div className="d-flex align-items-center" >
                                                        {
                                                            laptopItem.discount > 0 ?
                                                                <p className="card-text befor-price fs-5 mt-3 mx-4">
                                                                    {
                                                                        `${formatCurrency(laptopItem.price)} تومان `
                                                                    }
                                                                </p> :
                                                                <p className="d-none"></p>
                                                        }
                                                        <p className="card-text final-price fs-4 "> {
                                                            formatCurrency(discount(laptopItem.price, laptopItem.discount))
                                                        } </p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </SwiperSlide>
                        ))
                    }

                </Swiper>

            </div>

        </section>
    )
}

export default NewestLaptops