import { useState } from 'react'
import './Header.css'
import { FiMenu, FiUser, FiShoppingCart, FiSearch, FiChevronDown, FiX } from "react-icons/fi";
import { Link } from 'react-router-dom'

import logo from '../../assets/logo.png'

const Header = (props) => {
    const { totalLenght } = props

    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [menuOpen, setmenuOpen] = useState(false);

    return (
        <header>

            <div className="container">
                <div className="top-header d-flex align-items-center justify-content-between">

                    <Link to="/" >
                        <div className="logo-container">
                            <img src={logo} title=" خانه " className="w-100 h-100" />
                        </div>
                    </Link>

                    <div className="search-container">
                        <div className="input-group input-group-lg d-none d-sm-flex">
                            <button className="button btn1" type="button"> <FiSearch /> </button>
                            <input type="text" className="form-control" placeholder=" جستجوی کالا ..." />
                        </div>
                    </div>

                    <ul className="icon-container d-flex align-items-center justify-content-center gap-4">
                        <li><a href="#" className="d-block d-sm-none" title=" جستجو "> <FiSearch /> </a></li>
                        <Link to="/login" >
                            <li><a href="#" className="" title=" ورود / ثبت نام "  > <FiUser /> </a></li>
                        </Link>
                        <li><a href="#offcanvasExample"
                            id="shop-icon"
                            title=" سبد خرید "
                            data-bs-toggle="offcanvas"
                        >

                            <span className="shop-item-length"> {totalLenght} </span>
                            <FiShoppingCart />

                        </a>
                        </li>

                        <li><a href="#"
                            className="d-block d-sm-none"
                            title=" منو "
                            onClick={() => { setmenuOpen(!menuOpen) }} >
                            <FiMenu />
                        </a>
                        </li>
                    </ul>

                </div> {/* end of top-header */}
            </div> {/* end of container */}

            <ul className={`menu ${menuOpen ? 'active' : 'inactive'}`}  >

                <li className='fs-3 text-danger d-block d-sm-none'
                    id="close-btn"
                    onClick={() => { setmenuOpen(!menuOpen) }}  >
                    <FiX /> بستن منو </li>

                <Link to='/shop'>
                    <li onMouseOver={() => { setOpen1(true) }} onMouseLeave={() => { setOpen1(false) }} >
                        <a href=""> گوشی موبایل   <FiChevronDown />  </a>
                        <ul className={`dd-menu ${open1 ? 'active' : 'inactive'}`} >
                            <li><a href="#" className="dd-menu-item" > سامسونگ </a></li>
                            <li><a href="#" className="dd-menu-item" > اپل </a></li>
                            <li><a href="#" className="dd-menu-item" > شیاومی </a></li>
                            <li><a href="#" className="dd-menu-item" > هوآوی </a></li>
                        </ul>
                    </li>
                </Link>

                <Link to="/shop">
                    <li onMouseOver={() => { setOpen2(true) }} onMouseLeave={() => { setOpen2(false) }}>
                        <a href="">   لپ تاپ  <FiChevronDown />  </a>
                        <ul className={`dd-menu ${open2 ? 'active' : 'inactive'}`} >
                            <li><a href="#" className="dd-menu-item" > ایسوس </a></li>
                            <li><a href="#" className="dd-menu-item" > لنوو </a></li>
                            <li><a href="#" className="dd-menu-item" > اچ پی </a></li>
                        </ul>
                    </li>
                </Link>

                <Link to='/shop'>
                    <li><a href="">  هندزفری  </a></li>
                </Link>

                <Link to='/shop'>
                    <li><a href="">  پاور بانک  </a></li>
                </Link>

            </ul>



            {/* ******** a copy of bottom-header is in bottom ********* */}

            {/* <nav className="bottom-header ">

                <div className="container ">
                    <ul className="menu " >
                        <li onMouseOver={() => { setOpen1(true) }} onMouseLeave={() => { setOpen1(false) }} >
                            <a href=""> گوشی موبایل   <FiChevronDown />  </a>  
                            <ul className={`dd-menu ${open1 ? 'active' : 'inactive'}`} >
                                <li><a href="#" className="dd-menu-item" > سامسونگ </a></li>
                                <li><a href="#" className="dd-menu-item" > اپل </a></li>
                                <li><a href="#" className="dd-menu-item" > شیاومی </a></li>
                                <li><a href="#" className="dd-menu-item" > هوآوی </a></li>
                            </ul>
                        </li>

                        <li onMouseOver={() => { setOpen2(true) }} onMouseLeave={() => { setOpen2(false) }}>
                            <a href="">  لوازم جانبی موبایل  <FiChevronDown />  </a> 
                            <ul className={`dd-menu ${open2 ? 'active' : 'inactive'}`} >
                                <li><a href="#" className="dd-menu-item" > هندزفری </a></li>
                                <li><a href="#" className="dd-menu-item" > شارژر </a></li>
                                <li><a href="#" className="dd-menu-item" > قاب </a></li>
                                <li><a href="#" className="dd-menu-item" > گلس </a></li>
                            </ul>
                        </li>

                        <li><a href="">  مود و پوشاک </a></li>

                        <li><a href=""> کتاب و لوازم تحریر </a></li>

                    </ul>
                </div>
            </nav> */}

        </header>
    )
}

export default Header