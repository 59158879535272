import React from 'react'
import './Checkout.css'

import formatCurrency from '../../function/formatCurrency'
import discount from '../../function/discount'

const Checkout = (props) => {
    const { shopItems, totalPrice } = props
    return (
        <section className='checkout container mt-4' >

            <form className="mt-5 needs-validation " noValidate >
                <div className="row d-flex align-items-start justify-content-between">

                    <div className="col-12 col-md-6">
                        <h1 className="text-center" > جزئیات صورت حساب </h1>

                        <div className="row g-3">
                            <div className="col mb-3">
                                <label htmlFor="name" className="form-label"> نام  <span className="necessary">*</span> </label>
                                <input type="text" className="form-control form-control-lg " id="name" placeholder=" نام را وارد کنید " />
                            </div>

                            <div className="col mb-3">
                                <label htmlFor="family-name" className="form-label"> نام خانوادگی  <span className="necessary">*</span> </label>
                                <input type="text" className="form-control form-control-lg " id="family-name" placeholder=" نام خانوادگی را وارد کنید " />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col mb-3">
                                <label htmlFor="phone-number" className="form-label"> شماره تلفن <span className="necessary">*</span> </label>
                                <input type="tel" className="form-control form-control-lg " id="phone-number" placeholder=" 09123456789 " />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col mb-3">
                                <label htmlFor="city" className="form-label"> استان <span className="necessary">*</span> </label>
                                <select className="form-select form-select-lg " id="city" defaultValue="1" >
                                    {/* <option selected> استان محل سکونت خود را انتخاب کنید </option> */}
                                    <option value="1"> تهران </option>
                                    <option value="2"> خراسان رضوی </option>
                                    <option value="3"> اصفهان </option>
                                    <option value="4"> فارس </option>
                                    <option value="5"> خوزستان </option>
                                    <option value="6"> آذربایجان شرقی </option>
                                    <option value="7"> آذربایجان غربی </option>
                                    <option value="8"> مازندران </option>
                                    <option value="9"> کرمان </option>
                                    <option value="10"> سیستان و بلوچستان </option>
                                    <option value="11"> البرز </option>
                                    <option value="12"> گیلان </option>
                                    <option value="13"> کرمانشاه </option>
                                    <option value="14"> گلستان </option>
                                    <option value="15"> هرمزگان </option>
                                    <option value="16"> لرستان </option>
                                    <option value="17"> همدان </option>
                                    <option value="18"> کردستان </option>
                                    <option value="19"> مرکزی </option>
                                    <option value="20"> قم </option>
                                    <option value="21"> قزوین </option>
                                    <option value="22"> اردبیل </option>
                                    <option value="23"> بوشهر </option>
                                    <option value="24"> یزد </option>
                                    <option value="25"> زنجان </option>
                                    <option value="26"> چهارمحال و بختیاری </option>
                                    <option value="27"> خراسان شمالی </option>
                                    <option value="28"> خراسان جنوبی </option>
                                    <option value="29"> کهگیلویه و بویراحمد </option>
                                    <option value="30"> سمنان </option>
                                    <option value="31"> ایلام </option>
                                </select>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col mb-3">
                                <label htmlFor="address" className="form-label"> آدرس پستی <span className="necessary">*</span> </label>
                                <input type="text" className="form-control form-control-lg " id="address" placeholder=" آدرس خود را وارد کنید " />
                            </div>
                        </div>

                        <div className="row g-3">
                            <div className="col mb-3">
                                <label htmlFor="zipcode" className="form-label"> کد پستی  </label>
                                <input type="text" className="form-control form-control-lg " id="zipcode" placeholder="  کد پستی را وارد کنید (اختیاری) " />
                            </div>
                            <div className="col mb-3">
                                <label htmlFor="email" className="form-label">  ایمل </label>
                                <input type="email" className="form-control form-control-lg " id="email" placeholder="  ایمیل را وارد کنید (اختیاری) " />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col mb-3">
                                <label htmlFor="comment" className="form-label">  جزئیات سفارش </label>
                                <textarea name="comment" id="" cols="30" rows="10" className='form-control' ></textarea>
                            </div>
                        </div>

                    </div> {/* end of col 6 */}

                    <div className="col-12 col-md-5 bill p-5">

                        <h1 className="text-center mb-5"> سفارش شما </h1>

                        <div className="bill-item-header d-flex align-items-center justify-content-between px-4">
                            <h3> محصول </h3>
                            <h3> جمع جزء </h3>
                        </div> <hr className="mx-4" />

                        {
                            shopItems.map((shopItem) => (
                                <div key={shopItem.id} className="bill-item d-flex align-items-center justify-content-between px-4 text-secondary mt-4" >
                                    <p> {shopItem.name} ({shopItem.qty} عدد) </p>
                                    <p> {formatCurrency(discount(shopItem.price, shopItem.discount) * shopItem.qty)} تومان </p>
                                </div>

                            ))
                        }

                        <div className="bill-item-footer d-flex align-items-center justify-content-between px-4 mt-4">
                            <h1> مجموع </h1>
                            <h1 className="bill-item-footer-total-price" >  {formatCurrency(totalPrice)} <span className="h2" >تومان</span> </h1>
                        </div>
                        <button className=" mt-4 button btn1 w-100" > ثبت سفارش </button>

                    </div> {/* end of col 5 */}

                </div> {/* end of row */}
            </form>

        </section >
    )
}

export default Checkout