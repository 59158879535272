import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'

//import images
import img1 from '../../assets/enamad.png'
import img2 from '../../assets/samandehi.png'
import logo from '../../assets/logo.png'

//improt icons
import { RiWhatsappFill, RiWhatsappLine, RiInstagramFill, RiInstagramLine, RiTelegramFill, RiTelegramLine } from "react-icons/ri";

const Footer = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#501fc9" fillOpacity="1" d="M0,224L80,197.3C160,171,320,117,480,128C640,139,800,213,960,224C1120,235,1280,181,1360,154.7L1440,128L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path></svg>
            <footer>
                <div className="container text-white">
                    <div className="row row1" >
                        <div className="col-12 col-md-6 mb-4">
                            <p>
                                لورم ایپسوم متن ساختگی ب و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.
                            </p>
                        </div>

                        <div className="col-12 col-md-2 mb-4 me-5">
                            <h3> اطلاعات تماس </h3>
                            <p> پشتیبانی و فروش <br />
                                025-37712345 <br /><br />
                                ساعت پاسخ گویی <br />
                                9:00 الی 22:00 <br /><br />
                                تهران، میدان اول، مجتمع ابتدا، طبقه سوم، واحد 314
                            </p>
                        </div>

                        <div className="col-12 col-md-3 text-center d-flex align-items-center mb-4">
                            <img src={img1} alt="" />
                            <img src={img2} alt="" />
                        </div>
                    </div> {/* end of row1 */}

                    <div className="row row2 d-flex align-items-center justify-content-between text-center pb-4">
                        <Link to="/" >
                            <div className="col-12 col-md-4 " id="logo">
                                
                                    <img src={logo} alt="" />
                                
                            </div>
                        </Link>
                        <div className="col-12 col-md-4 text-center">
                            <p>
                                کلیه حقوق متعلق به مهدی فهیمی می باشد
                            </p>
                        </div>

                        <div className="col-12 col-md-4 links">
                            <a href="#" title=" واتساپ " > <RiWhatsappLine /> </a>
                            <a href="#" title=" اینستاگرام " > <RiInstagramLine /> </a>
                            <a href="#" title=" تلگرام " > <RiTelegramLine /> </a>
                        </div>
                    </div>

                </div>
            </footer>
        </>
    )
}

export default Footer