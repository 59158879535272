import React, { useState, useEffect } from 'react'
import './NewestMobile.css'
import { Link } from 'react-router-dom'

// Swiper imports
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, EffectFade, Autoplay } from "swiper";

// icon imports
import { FiShoppingCart } from "react-icons/fi";

//function imports
import discount from '../../function/discount'
import formatCurrency from '../../function/formatCurrency'

const NewestMobile = (props) => {
    const { mobileItems, addProduct, getItemid } = props

    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    useEffect(() => {

        const changeWidth = () => {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('resize', changeWidth)

        return () => {
            window.removeEventListener('resize', changeWidth)
        }

    }, [])

    return (
        <section className='NewestMobile mt-5' >
            <div className="container">

                <div className=" d-flex align-items-start justify-content-between " >
                    <h1 className='mb-5' > جدید ترین موبایل ها </h1>
                    <Link to="/shop" >
                        <button type="submit" className="button btn2"> مشاهده فروشگاه </button>
                    </Link>
                </div>

                <Swiper
                    autoplay={{ delay: 5000, disableOnInteraction: true }}
                    slidesPerView={screenWidth >= 1400 ? 4 : screenWidth >= 1070 ? 3 : screenWidth >= 800 ? 2 : 1}
                    spaceBetween={30}
                    navigation={true}
                    modules={[Pagination, Navigation, Autoplay]}
                    className="mySwiper d-block table-responsive " id='bottom-swiper'>

                    {
                        mobileItems.map((mobileItem) => (
                            <SwiperSlide key={mobileItem.id}>
                                 <Link to={`/shop/${mobileItem.id}`} onClick={() => { getItemid(mobileItem); window.scroll({ top: 0  }) }} key={mobileItem.id}>
                                    <div className="col" >
                                        <div className="card">
                                            <img src={mobileItem.photo} className="card-img-top" alt="" />
                                            {
                                                mobileItem.discount > 0 ?
                                                    <span> %{mobileItem.discount} </span> :
                                                    <span className="d-none" ></span>
                                            }
                                            <div className="card-body m-3">
                                                <h5 className="card-title"> گوشی {mobileItem.company} {mobileItem.name} با حافظه {mobileItem.memory} Gb با رم {mobileItem.ram} Gb </h5>
                                                <div className="price-and-btn-container">
                                                    <a
                                                        href="#offcanvasExample"
                                                        data-bs-toggle="offcanvas"
                                                        onClick={() => addProduct(mobileItem)}
                                                    >
                                                        <button
                                                            className="button btn1 card-text"
                                                            title=" افزودن به سبد خرید "
                                                        >
                                                            <FiShoppingCart />
                                                        </button>
                                                    </a>
                                                    <div className="d-flex align-items-center" >
                                                        {
                                                            mobileItem.discount > 0 ?
                                                                <p className="card-text befor-price fs-5 mt-3 mx-4">

                                                                    {`${formatCurrency(mobileItem.price)} تومان `}
                                                                </p> :
                                                                <p className="d-none"></p>
                                                        }
                                                        <p className="card-text final-price fs-4 ">
                                                            {
                                                                formatCurrency(discount(mobileItem.price, mobileItem.discount))
                                                            }
                                                        </p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </SwiperSlide>
                        ))
                    }

                </Swiper>

            </div>

        </section>
    )
}

export default NewestMobile